<template>
    <div>
        <div :style="{width: this.$oucy.pageWidth+'px', display: 'inline-block'}">
            <el-row>
                <el-col :span="5">
                    <div class="left_nav">
                        <myMenu :list="allGlobalClassifyList1" :default-active="QueryFurnitureLibrary.cardCategory" @menuSelect="menuSelect1">
                            <span class="bold_span m-b-20" style="padding-top:30px;padding-left: 20px; display: inline-block;">企业名片</span>
                        </myMenu>
                        <myMenu :list="allGlobalClassifyList0" :default-active="QueryFurnitureLibrary.cardCategory" @menuSelect="menuSelect">
                            <span class="bold_span m-b-20" style="padding-top:30px;padding-left: 20px; display: inline-block;">个人名片</span>
                        </myMenu>
                    </div>
                </el-col>
                <el-col :span="19">
                    <div class="middle p-40 text-left">
                        <div class="c-2 f14 pointer" @click="$oucy.back()">&lt; 返回</div>
                        <div class="df m-t-20">
                            <div><img :src="$oucy.ossUrl + detail.employeePic" class="enterpriseAvata"></div>
                            <div class=" m-l-20" style="width:100%">
                                <div class="c-1 f16 m-t-10 df-jc-s-b">
                                    <div>{{detail.employeeName}} <span v-if="detail.ltUserAuthOfCardUserauth && detail.ltUserAuthOfCardUserauth.userPersonalAuthenticationStatu" class="userPersonalAuthenticationStatu">已实名</span></div>
                                    <div>
                                        <el-button size="mini" type="primary" @click="openMessage">申请合作</el-button>
                                        <el-button size="mini" type="primary" @click="openMessage">在线联系</el-button>
                                    </div>
                                </div>
                                <div class="c-2 f14 m-t-10">{{detail.employeeJob}}</div>
                                <div class="df f14 m-t-10">
                                    <div class="m-r-70">人气({{detail.employeePopularity}})</div>
                                    <div class="m-r-70 pointer" @click="savelikeAddTime">点赞({{detail.employeeLike}})</div>
                                    <div class="m-r-70 pointer" @click="collectCardEmployee">收藏({{detail.employeeCollect}})</div>
                                    <div class="m-r-70 pointer" @click="sharePoster">转发({{detail.employeeTranspond}})</div>
                                </div>
                            </div>
                        </div>
                        <div class="bold f16 c-1 m-t-20">签名介绍</div>
                        <div class="c-6 f14 m-t-20">{{detail.cardSelf}}</div>
                        <div class="df boxs c-1 f12">
                            <div class="df box f1 ">
                                <img src="@/assets/images/card/mp_phone.png">
                                <div class="m-l-10">
                                    <div class="bold">联系电话</div>
                                    <div class="m-t-10">{{detail.employeePhone}}</div>
                                </div>
                            </div>
                            <div class="df box f2">
                                <img src="@/assets/images/card/mp_mail.png">
                                <div class="m-l-10">
                                    <div class="bold">联系邮箱</div>
                                    <div class="m-t-10">{{detail.employeeEmail}}</div>
                                </div>
                            </div>
                            <div class="df box f3">
                                <img src="@/assets/images/card/mp_address.png">
                                <div class="m-l-10">
                                    <div class="bold">联系地址</div>
                                    <div class="m-t-10">{{detail.employeeAdd}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="bold f16 c-1 m-t-20">企业信息</div>
                        <div class="enterpriseBox">
                            <lineTitle :text="enterpriseDetail.enterpriseName"></lineTitle>
                            <div class="c-1 f14 m-t-10 p-l-10"><p v-html="enterpriseDetail.enterpriseIntro" class="html"></p></div>
                     <!--        <lineTitle text="企业相册" class="m-t-30"></lineTitle>
                            <div class="c-1 f14 m-t-10 p-l-10">
                                <img class="m-r-10" src="@/assets/images/sofa1.png">
                                <img class="m-r-10" src="@/assets/images/sofa2.png">
                                <img class="m-r-10" src="@/assets/images/sofa3.png">
                            </div> -->
                        </div>
                        <div class="m-t-30">
                            <div>评论 <span>({{ReturnsObject.totalElements}})</span></div>
                            <div class="commentBox m-t-20">
                                <div class="left">
                                    <img v-if="loginUser.ltUserDetailFull" :src="$oucy.ossUrl+ loginUser.ltUserDetailFull.detailAvatar" class="pic">
                                </div>
                                <div class="right">
                                    <inputBox ref="inputBox" @callback="createCardPersonComment" btnText="发表评论"></inputBox>
                                </div>
                            </div>
                        </div>
                        <comment2 :list="ReturnsObject.content" :detail="detail"></comment2>
                        
                    </div>
                    <div class="pagination">
                        <el-pagination v-if="ReturnsObject.content" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="ReturnsObject.size" :total="ReturnsObject.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
                        </el-pagination>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 分享海报 -->
        <sharePoster ref="sharePoster"></sharePoster>
    </div>
</template>
<script>
import { cardEmployee, enterpriseinfo, ltIdentity, china, cardCategory, cardPerson, cardEnterprise, furnitureGlobalBrand,cardEmployeeComment } from "@/service/index.js"
import { localSet, localGet, localDel } from "@/store/store";
import oucy from "@/util/oucyUtil";

export default {
    name: "Card",
    data() {
        return {
            type: 1,
            bannerHeight: 1000,
            allGlobalClassifyList1: [],
            allGlobalClassifyList0: [],
            // 请求对象            
            QueryFurnitureLibrary: {
                limit: 10,
                start: 0,
                // enterpriseAuthenticationLevel: 0, //   认证级别：0全部 1实名认证 2信守约 3品质工厂
                cardPersonSort: 0, //  排序方式：0 销量[默认], 排序1 最新, 3 收藏, 4 人气,   
                cardRegion: null, //    位置区域
                siteId: null, //    站点
                cardCategory: null, //名片分类
                cardStatus:2,
            },
            // 返回对象
            ReturnsObject: {},


           
            detail: {},
            id: null,
            enterpriseId: null,
            brands: [],
            loginUser: {},
            enterpriseDetail: {},

        }
    },
    mounted() {
        this.id = this.$route.query.id
        this.enterpriseId = this.$route.query.enterpriseId
        this.QueryFurnitureLibrary.cardEmployee = this.id
        this.getEmployeeDetail()
        // this.getUserBrands()
        this.getCardCategoryList()
        // this.getCardEnterpriseDetail()
        this.getCardEmployeeCommentList()
        this.loginUser = localGet('loginUser') || '{}'
    },
    methods: {

        getCardCategoryList() {
            cardCategory.getCardCategoryList({ categoryType: 1 }).then(res => {
                this.allGlobalClassifyList1 = res || []
                this.allGlobalClassifyList1.unshift({ categoryName: "全部企业", id: "全部企业", categoryType: 1, categoryIconPc: "1e3c102c68fb4377943dd3861d2b59f9.png"})
                this.$oucy.keyAs(this.allGlobalClassifyList1, 'categoryName')
            })
            cardCategory.getCardCategoryList({ categoryType: 0 }).then(res => {
                this.allGlobalClassifyList0 = res || []
                this.allGlobalClassifyList0.unshift({ categoryName: "全部个人", id: "全部个人", categoryType: 0 ,categoryIconPc: "47ead6bab0ff454ab85d4ab901a68ca3.png" })
                this.$oucy.keyAs(this.allGlobalClassifyList0, 'categoryName')
            })
        },
        // 切换菜单回调
        menuSelect1(v) {
            this.type = 1
            console.log(v)
            this.$oucy.go(`/card?type=${this.type}&cardCategory=${v}`)
        },
        menuSelect(v) {
            this.type = 0
            console.log(v)
            this.$oucy.go(`/card?type=${this.type}&cardCategory=${v}`)
        },


        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary.limit = pageSize;
            me.getCardEmployeeCommentList();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.QueryFurnitureLibrary.start = current - 1;
            me.getCardEmployeeCommentList();
        },
        findChina() {
            china.findChinaTree({ chinaId: null }).then(res => {
                this.chinaList = res.children
                /*                chinaList=Array.from(res).map(item => ({
                                    value: item.chinaId,
                                    label: item.chinaName,
                                    leaf: !item.hasChildren
                                }));*/
            });
        },
        getEmployeeDetail() {
            cardEmployee.getEmployeeDetail({ id: this.id }).then(res => {
                this.detail = res || {}
                this.$oucy.setTitle(this.detail.employeeName)
                this.enterpriseId=res.enterpriseId
                this.getCardEnterpriseDetail()
                
            })
        },

        // 查询员工
        getCardEmployeeList() {
            cardEmployee.getCardEmployeeList(this.QueryFurnitureLibrary).then(res => {
                this.ReturnsObject = res || []
            })
        },
        // 发布个人名片评论
        createCardPersonComment(v) {

            let cardEmployeeCommentAttachs = []
            if (v.imageList.length) {
                for (let vv of v.imageList) {
                    cardEmployeeCommentAttachs.push({ cardEmployeeCommentAttachUrl: vv })
                }
            }
            let CreateCardEmployeeComment = {
                cardEmployee: this.id, //个人名片编码
                commentContent: v.text, //评论内容
                cardEmployeeCommentAttachs: v.imageList.length ? JSON.stringify(cardEmployeeCommentAttachs) : '[]', //评论附件
            }
            cardEmployeeComment.createCardCategory(CreateCardEmployeeComment).then(res => {
                this.$message({
                    type: 'success',
                    message: '评论成功!'
                });
                this.$refs.inputBox.reset()
                this.QueryFurnitureLibrary.start = 0
                this.getCardEmployeeCommentList()
            })
        },
        // 查询个人名片评论列表
        getCardEmployeeCommentList(v) {
            cardEmployeeComment.getCardEmployeeCommentList(this.QueryFurnitureLibrary).then(res => {
                this.ReturnsObject = res
            })
        },
        getCardEnterpriseDetail() {
            cardEnterprise.getCardEnterpriseDetail({ id: this.enterpriseId }).then(res => {
                this.enterpriseDetail = res || {}
            })
        },
        openMessage(){
            if(this.detail.employeeUser){
                this.openImMessage({id:this.detail.employeeUser})
            }else{
                this.$message('暂时无法联系')
            }
        },
        /**
         * 打开聊天框
         * @param  {[type]} targetUserId [description]
         * @return {[type]}              [description]
         */
        openImMessage(v){
            this.loginUser = localGet('loginUser')
            if(this.loginUser && this.loginUser.id){
                this.$oucy.app.eventBus.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE, {targetUserId:v.id});
            }else{
                this.$alert('请先登录','提示')
            }
            // this.$emit(this.$oucy.msgKeys.OPEN_IM_MESSAGE,v);
        },
        sharePoster(){
            let obj = {
                objectId: this.id,
                playbillCategory: 4,
                redirectUrlScene:{id:this.id}
            };
            this.$refs.sharePoster.show(obj,()=>{
                this.addCardEmployeeTransmit()
            })
        },
        // 增加员工名片分享数
        addCardEmployeeTransmit(){
            cardEmployee.addCardEmployeeTransmit({id:this.id}).then(res=>{
                ++this.detail.employeeTranspond
            })
        },
        // 点赞员工名片
        savelikeAddTime(){
            cardEmployee.savelikeAddTime({id:this.id}).then(res=>{
                ++this.detail.employeeLike
            })
        },
                // 收藏员工名片
        collectCardEmployee(){
            cardEmployee.collectCardEmployee({id:this.id}).then(res=>{
                this.detail.collect=!this.detail.collect
                if(this.detail.collect){
                    this.$message('收藏成功')
                    ++this.detail.employeeCollect
                }else{
                    --this.detail.employeeCollect
                    this.$message('取消收藏')
                }
            })
        },
    }
}
</script>
<style scoped lang="less">
.left_nav {
    margin-top: 20px;
}

.left_nav .el-menu {
    /*margin-left:20px;*/
    z-index: 1000;
}

.left_nav .el-button--text {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    margin: 30px 0 0 30px;
    color: #111111;
}

.left_nav .el-button--text:focus {
    color: #2090FF;
}

.el-menu {
    border-right: 0 !important;
    height: 900px;
}

.el-menu-item {
    font-size: 13px;
}

.nav img {
    margin-right: 8px;
}

.nav:hover .icon1 {
    display: none;
}

.nav:hover .icon2 {
    display: inline-block;
}

.icon2 {
    display: none;
}

.el-collapse {
    border: none;
}

.el-collapse-item__header {
    border: none;
}

.el-collapse-item__wrap {
    margin-top: 20px;
}

.top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
}

.place .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
    margin-bottom: 10px;
}

.place {
    margin-bottom: 20px;
    margin-left: -170px;
}

.place>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
}

.auth {
    margin-bottom: 30px;
    display: flex;
}

.auth>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.auth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
    margin-top: 10px;
    text-align: left;
}

.top_sort .el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.sort /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
}

.sort {
    margin-bottom: 30px;
    display: flex;
}

.sort>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.el-radio-button--text:hover {
    color: rgb(32, 144, 255) !important;
}

.el-radio-button--mini {
    border: none;
}

.el-radio-button--mini:hover {
    background-color: rgb(32, 144, 255) !important;
}

.el-radio-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

/deep/ .el-collapse-item__wrap {
    margin-top: 20px;
    text-align: center;
}


.personal_card ul li {
    height: 250px;
}

.enterprise_card {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 10px;
}

.enterprise_card span {
    width: 160px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    float: left;
    display: flex;
    padding: 25px 0 0 10px;
}

.com_head {
    width: 80px;
    height: 80px;
    border: 1px rgb(243, 243, 243) solid;
    float: left;
}

.com_head img {
    margin: 15px auto;
    width: 100%;
    height: 100%;
}

.enterprise_card .info {
    width: 80px;
    /*height: 43px;*/
    background: #F8F8F9;
    border-radius: 2px;
    /*margin-top: 30px;*/
    margin-left: 6px;
}

.enterprise_card .info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    /*margin-top: -20px;*/
}

.enterprise_card .info p+p {
    /*padding-top: 10px;*/
}

.personal_card .info {
    width: 57px;

    background: #F8F8F9;
    border-radius: 2px;

}

.personal_card .info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    line-height: 20px;
    text-align: center;
    /*margin-top: -20px;*/
}

.staff_info {
    width: 88px;
}

.staff_info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    text-align: center;
}

.contact {
    width: 233px;
    /*height: 50px;*/
    background-color: rgb(243, 249, 255);
    margin: 6px auto;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.contact .el-button--text {
    width: 70px;
    border: 1px rgb(32, 144, 255) solid;
    float: right;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.pagination {
    margin: 30px auto 120px;
}

/*/deep/ .btn-next {
    width: 128px;
    height: 46px !important;
    background: #FFFFFF !important;
}

/deep/ .btn-prev {
    width: 128px;
    height: 46px !important;
    background: #FFFFFF !important;
}

/deep/ .el-pager {
    position: relative;
    top: 9px;
}

/deep/ .number {
    background: #FFFFFF !important;
}

/deep/ .active {
    background: #2090ff !important;
}*/

.bus_recomm {
    width: 100%;
}

.left_nav {
    margin-top: 20px;
}

.left_nav .el-menu {
    /*margin-left:20px;*/
    z-index: 1000;
}

.el-menu {
    border-right: 0 !important;
    height: 900px;
}

.el-menu-item {
    font-size: 13px;
}

.nav img {
    margin-right: 8px;
}

.nav:hover .icon1 {
    display: none;
}

.nav:hover .icon2 {
    display: inline-block;
}

.icon2 {
    display: none;
}

.bold_span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    /*margin: 30px 0 0 30px;*/
}

.top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
}

.place .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
    margin-bottom: 10px;
}

.place {
    text-align: left;
    margin-bottom: 20px;
    /*margin-left: -170px;*/
    padding-left: 10px;
}

.place>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
}

.auth {
    margin-bottom: 30px;
    display: flex;
}

.auth>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.auth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
    /*margin-top: 10px;*/
    text-align: left;
}

.el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.sort /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
}

.sort {
    margin-bottom: 30px;
    display: flex;
}

.sort>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.el-radio-button--text:hover {
    color: rgb(32, 144, 255) !important;
}

.el-radio-button--mini {
    border: none;
}

.el-radio-button--mini:hover {
    background-color: rgb(32, 144, 255) !important;
}

.el-radio-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

/deep/ .el-collapse-item__wrap {
    margin-top: 20px;
    text-align: center;
}



.com_head {
    width: 80px;
    height: 80px;
    border: 1px rgb(243, 243, 243) solid;
    float: left;
}

.com_head img {
    width: 73px;
    height: 47px;
    margin-top: 15px;
}




.middle_card_star {
    float: left;
    display: flex;
    margin-left: 10px;
}

.info {
    width: 70px;
    /*height: 43px;*/
    background-color: rgb(248, 248, 249);
    padding: 2px;
    margin: 15px auto 0px;
}

.info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 6px;
}

.goods_info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    text-align: left;
    margin-left: 7px;
}

.pagination {
    margin-top: 50px;
    line-height: 150px;
}


.middle ul li:nth-child(3n) {
    margin-right: 0px;
}



.items {
    margin-left: 10px;
}

.item {
    padding: 20px;
    background: #fff;
    margin-top: 10px;
    display: flex;
}

.logo {
    width: 168px;
    height: 168px;
    background: #FDFDFD;
    border: 1px solid #F3F3F3;
    border-radius: 1px;
}

.dot {
    padding-left: 30px;
    flex: 1;
}

.itemTop {
    align-items: flex-start;
}

.icon3 {}

.icon4 {
    margin-right: 5px;
}

.cover {
    width: 80px;
    margin-left: 10px;
}

.myauth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    /*margin-left: 10px;*/
    /*margin-top: 10px;*/
    text-align: left;
}

.mybutton {
    margin: 0 10px 10px 0;
    background: #F6F7FB;
    border: none;
    color: #606266;
    padding: 6px 12px;
    border-radius: 4px;
}

.text-left {
    text-align: left;

}

.myauth {
    display: flex;
    text-align: left;
}

.myauth .myauthLift {
    font-weight: bold;
    display: block;
    width: 90px;
}

.myauth .text-left {
    flex: 1;
}

.selectedChina {
    color: #fff;
    background-color: rgb(32, 144, 255);
}

.el-radio-group {
    text-align: left;
}

.middle {
    background: #fff;
    margin-top: 20px;
    margin-left: 20px;
}

.enterpriseAvata {
    width: 90px;
    height: 90px;
}

.box1 {
    margin-right: 20px;
    padding: 20px;
    flex: 1;
    background: #F8F8F9;
}

.cardCode {
    width: 90px;
    height: 90px;
}

.box2s {
    display: flex;
    flex-wrap: wrap;
}

.box2 {
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px;
    box-sizing: border-box;
    background: #FAFAFA;
    background-color: #ffffff;
    padding-bottom: 15px;
    border: 1px solid #EAEAEA;
    text-align: center;
    width: 218px;

    &:hover {

        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
        background: #FFFFFF;
    }

    .box img {
        width: 100%;
        // height: 163px;
    }
}

.box3s {
    display: flex;
    flex-wrap: wrap;
}

.box3 {
    display: flex;
    justify-content: space-between;
    border: 1px solid #EAEAEA;
    width: 410px;
    padding: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.employeePic {
    width: 80px;
    height: 80px;
}

.boxs {
    margin-left: -4px;
    margin-right: -4px;

    .box {
        background: #F6F7F8;
        border-radius: 1px;
        padding: 10px;
        margin: 10px 4px;
    }

    .f1 {
        flex: 1;
    }

    .f2 {
        flex: 2;
    }

    .f3 {
        flex: 3;
    }
}

.enterpriseBox {
    margin-top: 20px;
    background: #F6F7F8;
    border-radius: 1px;
    padding: 30px;
}

.userPersonalAuthenticationStatu {
    background: #76CB68;
    border-radius: 20px;
    color: #fff;
    font-size: 12px;
    padding: 4px 8px;
}

.commentBox {
    display: flex;
}

.pic {
    width: 42px;
    height: 42px;
    border-radius: 42px;
}

.right {
    flex: 1;
    padding-left: 10px
}

.name {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
}

.time {
    font-size: 12px;
    font-family: SimSun;
    font-weight: 400;
    color: #888888;
}

.comment {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #555C68;
}

</style>